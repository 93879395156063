/**
 * @copyright 2019 Christoph Wurst <christoph@winzerhof-wurst.at>
 *
 * @author Christoph Wurst <christoph@winzerhof-wurst.at>
 * @author Jan-Christoph Borchardt <hey@jancborchardt.net>
 * @author John Molakvoæ <skjnldsv@protonmail.com>
 * @author nacho <nacho@ownyourbits.com>
 * @author Vincent Petry <vincent@nextcloud.com>
 *
 * @license AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 *
 */

/* globals Snap */
import _ from 'underscore';
import $ from 'jquery';
import moment from 'moment';
import cssVars from 'css-vars-ponyfill';
import { initSessionHeartBeat } from './session-heartbeat';
import OC from './OC/index';
import { setUp as setUpContactsMenu } from './components/ContactsMenu';
import { setUp as setUpMainMenu } from './components/MainMenu';
import { setUp as setUpUserMenu } from './components/UserMenu';
import PasswordConfirmation from './OC/password-confirmation'; // keep in sync with core/css/variables.scss

var breakpointMobileWidth = 1024;

var resizeMenu = function resizeMenu() {
  var appList = $('#appmenu li');
  var rightHeaderWidth = $('.header-right').outerWidth();
  var headerWidth = $('header').outerWidth();
  var usePercentualAppMenuLimit = 0.67;
  var minAppsDesktop = 12;
  var availableWidth = headerWidth - $('#nextcloud').outerWidth() - (rightHeaderWidth > 210 ? rightHeaderWidth : 210);
  var isMobile = $(window).width() < breakpointMobileWidth;

  if (!isMobile) {
    availableWidth = availableWidth * usePercentualAppMenuLimit;
  }

  var appCount = Math.floor(availableWidth / $(appList).width());

  if (isMobile && appCount > minAppsDesktop) {
    appCount = minAppsDesktop;
  }

  if (!isMobile && appCount < minAppsDesktop) {
    appCount = minAppsDesktop;
  } // show at least 2 apps in the popover


  if (appList.length - 1 - appCount >= 1) {
    appCount--;
  }

  $('#more-apps a').removeClass('active');
  var lastShownApp;

  for (var k = 0; k < appList.length - 1; k++) {
    var name = $(appList[k]).data('id');

    if (k < appCount) {
      $(appList[k]).removeClass('hidden');
      $('#apps li[data-id=' + name + ']').addClass('in-header');
      lastShownApp = appList[k];
    } else {
      $(appList[k]).addClass('hidden');
      $('#apps li[data-id=' + name + ']').removeClass('in-header'); // move active app to last position if it is active

      if (appCount > 0 && $(appList[k]).children('a').hasClass('active')) {
        $(lastShownApp).addClass('hidden');
        $('#apps li[data-id=' + $(lastShownApp).data('id') + ']').removeClass('in-header');
        $(appList[k]).removeClass('hidden');
        $('#apps li[data-id=' + name + ']').addClass('in-header');
      }
    }
  } // show/hide more apps icon


  if ($('#apps li:not(.in-header)').length === 0) {
    $('#more-apps').hide();
    $('#navigation').hide();
  } else {
    $('#more-apps').show();
  }
};

var initLiveTimestamps = function initLiveTimestamps() {
  // Update live timestamps every 30 seconds
  setInterval(function () {
    $('.live-relative-timestamp').each(function () {
      var timestamp = parseInt($(this).attr('data-timestamp'), 10);
      $(this).text(moment(timestamp).fromNow());
    });
  }, 30 * 1000);
};
/**
 * Moment doesn't have aliases for every locale and doesn't parse some locale IDs correctly so we need to alias them
 */


var localeAliases = {
  zh: 'zh-cn',
  zh_Hans: 'zh-cn',
  zh_Hans_CN: 'zh-cn',
  zh_Hans_HK: 'zh-cn',
  zh_Hans_MO: 'zh-cn',
  zh_Hans_SG: 'zh-cn',
  zh_Hant: 'zh-hk',
  zh_Hant_HK: 'zh-hk',
  zh_Hant_MO: 'zh-mo',
  zh_Hant_TW: 'zh-tw'
};
var locale = OC.getLocale();

if (Object.prototype.hasOwnProperty.call(localeAliases, locale)) {
  locale = localeAliases[locale];
}
/**
 * Set users locale to moment.js as soon as possible
 */


moment.locale(locale);
/**
 * Initializes core
 */

export var initCore = function initCore() {
  var userAgent = window.navigator.userAgent;
  var edge = userAgent.indexOf('Edge/');

  if (edge > 0) {
    $('html').addClass('edge');
    console.info('Legacy browser detected, applying css vars polyfill');
    cssVars({
      watch: true,
      //  set edge < 16 as incompatible
      onlyLegacy: !(/Edge\/([0-9]{2})\./i.test(navigator.userAgent) && parseInt(/Edge\/([0-9]{2})\./i.exec(navigator.userAgent)[1]) < 16)
    });
  }

  $(window).on('unload.main', function () {
    OC._unloadCalled = true;
  });
  $(window).on('beforeunload.main', function () {
    // super-trick thanks to http://stackoverflow.com/a/4651049
    // in case another handler displays a confirmation dialog (ex: navigating away
    // during an upload), there are two possible outcomes: user clicked "ok" or
    // "cancel"
    // first timeout handler is called after unload dialog is closed
    setTimeout(function () {
      OC._userIsNavigatingAway = true; // second timeout event is only called if user cancelled (Chrome),
      // but in other browsers it might still be triggered, so need to
      // set a higher delay...

      setTimeout(function () {
        if (!OC._unloadCalled) {
          OC._userIsNavigatingAway = false;
        }
      }, 10000);
    }, 1);
  });
  $(document).on('ajaxError.main', function (event, request, settings) {
    if (settings && settings.allowAuthErrors) {
      return;
    }

    OC._processAjaxError(request);
  });
  initSessionHeartBeat();
  OC.registerMenu($('#expand'), $('#expanddiv'), false, true); // toggle for menus

  $(document).on('mouseup.closemenus', function (event) {
    var $el = $(event.target);

    if ($el.closest('.menu').length || $el.closest('.menutoggle').length) {
      // don't close when clicking on the menu directly or a menu toggle
      return false;
    }

    OC.hideMenus();
  });
  setUpMainMenu();
  setUpUserMenu();
  setUpContactsMenu(); // move triangle of apps dropdown to align with app name triangle
  // 2 is the additional offset between the triangles

  if ($('#navigation').length) {
    $('#header #nextcloud + .menutoggle').on('click', function () {
      $('#menu-css-helper').remove();
      var caretPosition = $('.header-appname + .icon-caret').offset().left - 2;

      if (caretPosition > 255) {// if the app name is longer than the menu, just put the triangle in the middle
      } else {
        $('head').append('<style id="menu-css-helper">#navigation:after { left: ' + caretPosition + 'px }</style>');
      }
    });
    $('#header #appmenu .menutoggle').on('click', function () {
      $('#appmenu').toggleClass('menu-open');

      if ($('#appmenu').is(':visible')) {
        $('#menu-css-helper').remove();
      }
    });
  }

  $(window).resize(resizeMenu);
  setTimeout(resizeMenu, 0); // just add snapper for logged in users
  // and if the app doesn't handle the nav slider itself

  if ($('#app-navigation').length && !$('html').hasClass('lte9') && !$('#app-content').hasClass('no-snapper')) {
    // App sidebar on mobile
    var snapper = new Snap({
      element: document.getElementById('app-content'),
      disable: 'right',
      maxPosition: 300,
      // $navigation-width
      minDragDistance: 100
    });
    $('#app-content').prepend('<div id="app-navigation-toggle" class="icon-menu" style="display:none" tabindex="0"></div>'); // keep track whether snapper is currently animating, and
    // prevent to call open or close while that is the case
    // to avoid duplicating events (snap.js doesn't check this)

    var animating = false;
    snapper.on('animating', function () {
      // we need this because the trigger button
      // is also implicitly wired to close by snapper
      animating = true;
    });
    snapper.on('animated', function () {
      animating = false;
    });
    snapper.on('start', function () {
      // we need this because dragging triggers that
      animating = true;
    });
    snapper.on('end', function () {
      // we need this because dragging stop triggers that
      animating = false;
    }); // These are necessary because calling open or close
    // on snapper during an animation makes it trigger an
    // unfinishable animation, which itself will continue
    // triggering animating events and cause high CPU load,
    //
    // Ref https://github.com/jakiestfu/Snap.js/issues/216

    var oldSnapperOpen = snapper.open;
    var oldSnapperClose = snapper.close;

    var _snapperOpen = function _snapperOpen() {
      if (animating || snapper.state().state !== 'closed') {
        return;
      }

      oldSnapperOpen('left');
    };

    var _snapperClose = function _snapperClose() {
      if (animating || snapper.state().state === 'closed') {
        return;
      }

      oldSnapperClose();
    }; // Needs to be deferred to properly catch in-between
    // events that snap.js is triggering after dragging.
    //
    // Skipped when running unit tests as we are not testing
    // the snap.js workarounds...


    if (!window.TESTING) {
      snapper.open = function () {
        _.defer(_snapperOpen);
      };

      snapper.close = function () {
        _.defer(_snapperClose);
      };
    }

    $('#app-navigation-toggle').click(function (e) {
      // close is implicit in the button by snap.js
      if (snapper.state().state !== 'left') {
        snapper.open();
      }
    });
    $('#app-navigation-toggle').keypress(function (e) {
      if (snapper.state().state === 'left') {
        snapper.close();
      } else {
        snapper.open();
      }
    }); // close sidebar when switching navigation entry

    var $appNavigation = $('#app-navigation');
    $appNavigation.delegate('a, :button', 'click', function (event) {
      var $target = $(event.target); // don't hide navigation when changing settings or adding things

      if ($target.is('.app-navigation-noclose') || $target.closest('.app-navigation-noclose').length) {
        return;
      }

      if ($target.is('.app-navigation-entry-utils-menu-button') || $target.closest('.app-navigation-entry-utils-menu-button').length) {
        return;
      }

      if ($target.is('.add-new') || $target.closest('.add-new').length) {
        return;
      }

      if ($target.is('#app-settings') || $target.closest('#app-settings').length) {
        return;
      }

      snapper.close();
    });
    var navigationBarSlideGestureEnabled = false;
    var navigationBarSlideGestureAllowed = true;
    var navigationBarSlideGestureEnablePending = false;

    OC.allowNavigationBarSlideGesture = function () {
      navigationBarSlideGestureAllowed = true;

      if (navigationBarSlideGestureEnablePending) {
        snapper.enable();
        navigationBarSlideGestureEnabled = true;
        navigationBarSlideGestureEnablePending = false;
      }
    };

    OC.disallowNavigationBarSlideGesture = function () {
      navigationBarSlideGestureAllowed = false;

      if (navigationBarSlideGestureEnabled) {
        var endCurrentDrag = true;
        snapper.disable(endCurrentDrag);
        navigationBarSlideGestureEnabled = false;
        navigationBarSlideGestureEnablePending = true;
      }
    };

    var toggleSnapperOnSize = function toggleSnapperOnSize() {
      if ($(window).width() > breakpointMobileWidth) {
        snapper.close();
        snapper.disable();
        navigationBarSlideGestureEnabled = false;
        navigationBarSlideGestureEnablePending = false;
      } else if (navigationBarSlideGestureAllowed) {
        snapper.enable();
        navigationBarSlideGestureEnabled = true;
        navigationBarSlideGestureEnablePending = false;
      } else {
        navigationBarSlideGestureEnablePending = true;
      }
    };

    $(window).resize(_.debounce(toggleSnapperOnSize, 250)); // initial call

    toggleSnapperOnSize();
  }

  initLiveTimestamps();
  PasswordConfirmation.init();
};